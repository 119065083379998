import React, { PureComponent, ReactNode } from "react"
import styled from "styled-components"
import axios from "axios"
import {Typography} from "@material-ui/core"
import { Document, Page } from "react-pdf"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowRight, faFileDownload } from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/layout"
import theme from "../theme"
import Modal from "../components/modal"
import ContactInfo from "../components/contactInfo"
import Section, {Header, SubHeader, Divider} from "../components/section/section"

// Image imports
import Brochure from "../documents/strangeland-recording-brochure.pdf"
import { WindowLocation } from "@reach/router";

interface IconWrapperProps {
    shouldDisable: boolean
}

interface PdfDocumentProps extends IconWrapperProps {
    handleClick: () => void
}

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255, 0.8);
    width: 75px;
    cursor: pointer;
    pointer-events: ${(props: IconWrapperProps) => props.shouldDisable ? "none" : "auto"};
    opacity: ${(props: IconWrapperProps) => props.shouldDisable ? "0.5" : "1.0"};
`

const PreviousPage: React.SFC<PdfDocumentProps> = (props) => (
    <IconWrapper
        onClick={props.handleClick}
        shouldDisable={props.shouldDisable}>
        <FontAwesomeIcon icon={faArrowLeft} />
    </IconWrapper>
)

const NextPage: React.SFC<{handleClick: () => void, shouldDisable: boolean}> = (props) => (
    <IconWrapper
        onClick={props.handleClick}
        shouldDisable={props.shouldDisable}>
        <FontAwesomeIcon icon={faArrowRight} />
    </IconWrapper>
)

interface AboutPageProps {
    location: WindowLocation,
    children: ReactNode[]
}

interface AboutPageState {
    isMapToggled: boolean,
    currentPage: number,
    numberOfPages: number
}

export default class About extends PureComponent<AboutPageProps, AboutPageState> {
    constructor(props: AboutPageProps) {
        super(props)

        this.toggleFloorPlan = this.toggleFloorPlan.bind(this)
        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this)
        this.handlePreviousPage = this.handlePreviousPage.bind(this)
        this.handleNextPage = this.handleNextPage.bind(this)
        this.downloadBrochure = this.downloadBrochure.bind(this)

        this.state = {
            isMapToggled: false,
            currentPage: 1,
            numberOfPages: 0
        }
    }

    toggleFloorPlan() : void {
        this.setState({
            isMapToggled: !this.state.isMapToggled
        })
    }

    onDocumentLoadSuccess(numPages: number) : void {
        this.setState({
            currentPage: 1,
            numberOfPages: numPages
        })
    }

    handlePreviousPage() : void {
        this.setState({
            currentPage: this.state.currentPage !== 0 ? this.state.currentPage - 1 : 0
        })
    }

    handleNextPage() : void {
        this.setState({
            currentPage: this.state.currentPage !== this.state.numberOfPages ? this.state.currentPage + 1 : this.state.numberOfPages
        })
    }

    downloadBrochure() : void {
        axios({
            url: Brochure,
            method: "GET",
            responseType: "blob",
          }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", "the-venue-of-hollywood-brochure.pdf")
            link.click()
            window.URL.revokeObjectURL(url)
          })
    }

    render() {
        const modalizedMap = this.state.isMapToggled ? (
            <Modal handleClose={this.toggleFloorPlan} />
        ) : null

        const PDFDocument = typeof window !== "undefined" && window.innerWidth > 768 ? (
            <Section
                margin="40px auto"
                boxShadow="0px 2px 10px -3px black"
                borderRadius="2px">
                <PreviousPage handleClick={this.handlePreviousPage} shouldDisable={this.state.currentPage === 1} />
                <Document 
                    file={Brochure}
                    onDocumentLoadSuccess={this.onDocumentLoadSuccess}>
                    <Page pageNumber={this.state.currentPage} />
                </Document>
                <NextPage handleClick={this.handleNextPage} shouldDisable={this.state.currentPage === this.state.numberOfPages} />
            </Section>
        ) : null

        return(
            <Layout location={this.props.location}>
                <Section width="100%" margin="0" flexDirection="column">
                    <Section 
                        flexDirection="column" 
                        margin="0" >
                        <Header>Header</Header>
                        <SubHeader maxWidth="60%">
                            Subheader
                        </SubHeader>
                        <Divider themeColor={theme.palette.primary.main} />
                    </Section>
                    <Section 
                        id="map"
                        width="100%"
                        flexDirection="column"
                        margin="0 auto" 
                        padding="0 20px"
                        themeColor={theme.palette.primary.main}>
                        <Section 
                            maxWidth="80vw"
                            width="auto"
                            flexDirection="column"
                            margin="40px auto">
                            <Typography 
                                variant="subheading"
                                gutterBottom={true}
                                style={{
                                    color: "white"
                                }}>
                                Legend
                            </Typography>
                            <Typography 
                                variant="subheading"
                                gutterBottom={true}
                                style={{
                                    color: "white"
                                }}>
                                Contact
                            </Typography>
                            <ContactInfo margin="0" color="white"/>
                        </Section>
                    </Section>
                    {modalizedMap}
                    <Section
                        margin="0"
                        padding="40px 0"
                        flexDirection="column"
                        width="100%"
                        background="whitesmoke">
                        <Section 
                            id="brochure"
                            flexDirection="column" 
                            margin="0">
                            <Header>Brochure</Header>
                            <Divider themeColor={theme.palette.primary.main}/>
                        </Section>
                        {PDFDocument}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center"
                            }}>
                            <FontAwesomeIcon icon={faFileDownload}/>
                            <p style={{margin: "0 0 0 20px", cursor: "pointer", color: theme.palette.primary.light}} onClick={this.downloadBrochure}>Download</p>
                        </div>
                    </Section>
                </Section>
            </Layout>
        )
    }
}